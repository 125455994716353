<template>
    <div class="main-contents">
        <div class="search_wrap">
            <div class="path">총 {{ projectTotalCount }}개의 검색결과가 있습니다</div>
            
            <!-- list -->
            <div class="search-list">
                <ul v-if="projectTotalCount != 0 ">
                    <li>
                        <div>
                            <span class="numb">NO</span>
                            <span class="prj-name">프로젝트명</span>
                        </div>
                    </li>
                    

                    <li v-for="(project, idx) in projectList" :key="idx">
                        <a>
                            <span class="numb">{{(pageInfo.pageUnit * (pageInfo.pageIndex-1)) + idx+1}}</span>
                            <span class="text">
                                <span v-if="project.projectDivCd == 'PARENT' || project.projectDivCd == '31' || project.projectDivCd == '32'" class="sco-label" >공급기업지정</span> {{ project.projectNm }}
                            </span>
                            <span class="prj-btn" @click="projectInfoDetail(project)">
                                <div class="btn">프로젝트 보기 &#9654;</div>
                            </span>
                        </a>
                    </li>
                </ul>


                <ul v-else>
                    <li>
                        <div>
                            <span class="numb">NO</span>
                            <span class="prj-name">프로젝트명</span>
                        </div>
                    </li>

                    <li>
                        <!-- 검색된 프로젝트가 없을 경우 -->
                        <span class="search-list-none">
                            <span class="req-none-txt">
                                검색된 프로젝트가 없습니다!
                            </span>
                        </span>
                    </li>
                </ul>

            </div>

            <PagingComp :pageInfo="pageInfo" @page-click="goPage"/>

        </div>

    </div>
</template>

<script>
import PagingComp from '@/components/PagingComp.vue';

export default {

    components : {
		PagingComp ,
	},

	data() {
		return {
            input: {
                pageIndex : '1',
            },

            pageInfo : {},
            projectTotalCount : {},
            projectList : {},
		}
	},
    
	beforeMount(){
        this.input = {
            searchKeyword : this.$route.query.keyword,
            mberDivCd : this.$store.state.userInfo.mberDivCd
        }

        this.getPrjList();
	},

    destroyed() {
        this.$emit('destroyKeyword');
    },

    methods: {
        getPrjList(isScroll = true){
            this.$.httpPost('/api/main/getMainSrhProjectList', this.input)
				.then(res => {
					// console.log(res.data);
					this.projectList	= res.data.list;
					this.pageInfo		= res.data.pageInfo;
					this.projectTotalCount 	= res.data.totalCount;	 

					if(isScroll) {
						window.scroll(0, 0);
					}
				})
				.catch(err => {
					alert(err.response.data.error_description);
				});
        },

        goPage(page) {
			// console.log(page);
			this.input.pageIndex = page;
			this.getPrjList();
		},

        projectInfoDetail(project){
            //this.$router.push({name:'PRJ101M02',  query : {projectSeq : project.projectSeq}});


            if(this.input.mberDivCd) {
                var pageId = ''
                var projectSeq = project.projectSeq

                if(this.input.mberDivCd == '11' )       pageId = 'PRJ101M02';

                else if(this.input.mberDivCd == '21' && project.projectDivCd != 'PARENT')   pageId = 'PRJ201M02'; // 일반
                else if(this.input.mberDivCd == '21' && project.projectDivCd == 'PARENT') { // 공급
                    pageId = 'PRJ201M03'; 
                    projectSeq = project.parentProjectSeq;
                } 

                else if(this.input.mberDivCd == '22' && project.projectDivCd != 'PARENT')   pageId = 'PRJ303M01'; // 일반
                else if(this.input.mberDivCd == '22' && project.projectDivCd == 'PARENT') { // 공급
                    pageId = 'PRJ309M02'; 
                    projectSeq = project.parentProjectSeq;
                }  

                else if(this.input.mberDivCd == '31')   pageId = 'PRJ405M03';

                this.$router.push({name : pageId, query : {projectSeq : projectSeq}});

			} else {
				this.$.popup('/tec/prj/PRJ101P03')
					.then(res => {
						if(res) {
							// 회원가입 화면으로 이동
							this.$router.push({name : 'MEM003M01'});
						}
					});
			}


        }
    }
}
</script>
